import { memo, useRef, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import useMedias from '@Hooks/useMedias';
import styles from './Video.module.css';

import type { FC, VideoHTMLAttributes } from 'react';

export interface VideoProps extends Omit<VideoHTMLAttributes<HTMLElement>, 'muted'> {
  className?: string;
  lazy?: boolean;
  muted?: boolean;
}

const Video: FC<VideoProps> = (props) => {
  const { className, muted = true, poster, lazy = true, ...restProps } = props;
  const video = useRef<HTMLVideoElement>(null);
  const { isMobile } = useMedias();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [refVideoContainer, inViewVideo] = useInView({ rootMargin: '-1px' });

  const handleCanPlay = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (isLoaded) {
      video.current
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch(() => {
          setIsPlaying(false);
        });
    }

    if (!inViewVideo && isPlaying && isLoaded) {
      video.current.pause();
    }
  }, [inViewVideo, isPlaying, isLoaded]);

  useEffect(() => {
    setIsLoaded(false);
  }, [isMobile]);

  return (
    <div className={cn(styles.video, className)} ref={refVideoContainer}>
      <Img className={styles.poster} src={poster} loading={lazy ? 'lazy' : 'eager'} />
      {(inViewVideo || isLoaded) && (
        <video
          className={styles.player}
          {...restProps}
          muted={muted}
          ref={video}
          onCanPlay={handleCanPlay}
        />
      )}
    </div>
  );
};

export default memo(Video);
