import type { FC, SVGProps } from 'react';

const CopyIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.18528 21.2908V24.6336C9.18528 26.2167 10.4686 27.5 12.0517 27.5H25.1336C26.7167 27.5 28 26.2167 28 24.6336V11.5755C28 9.99245 26.7167 8.70912 25.1336 8.70912H22.0142M3 18.4244V5.3664C3 3.78329 4.28334 2.5 5.8664 2.5H18.9484C20.5314 2.5 21.8148 3.78329 21.8148 5.3664V18.4244C21.8148 20.0075 20.5314 21.2908 18.9484 21.2908H5.8664C4.28334 21.2908 3 20.0075 3 18.4244Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CopyIcon;
