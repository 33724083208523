import type { FC, SVGProps } from 'react';

const CopiedIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M26.75 15.5C26.75 21.9893 21.4893 27.25 15 27.25C8.51066 27.25 3.25 21.9893 3.25 15.5C3.25 9.01065 8.51065 3.75 15 3.75C21.4893 3.75 26.75 9.01065 26.75 15.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M20.3627 12.5234L13.6572 18.4758L9.64844 15.2707'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default CopiedIcon;
