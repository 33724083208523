import { useRef } from 'react';
import { cn } from '@divlab/divanui';

import styles from './Tooltip.module.css';

import type { FC, HTMLAttributes, PropsWithChildren } from 'react';

interface TooltipProps extends HTMLAttributes<HTMLDivElement>, PropsWithChildren {
  timerMs: number;
  visible?: boolean;
}

const Tooltip: FC<TooltipProps> = (props) => {
  const { className, timerMs = 3000, visible, children, ...restProps } = props;
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipTransitionMs = 200;

  return (
    <div
      className={cn(styles.tooltip, className, { [styles.visible]: visible })}
      style={{
        animationDelay: `${timerMs - tooltipTransitionMs}ms`,
        animationDuration: `${tooltipTransitionMs}ms`,
        transition: `opacity ${tooltipTransitionMs}ms`,
      }}
      ref={tooltipRef}
      {...restProps}
    >
      {children}
    </div>
  );
};

export default Tooltip;
